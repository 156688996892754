import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import TextLabel from 'components/login/TextLabel';
import { validatePasswordv2 } from 'utils/validate';

const PasswordValidation: React.FC<{
  password: string;
}> = ({ password }) => {
  const [isValidLength, setIsValidLength] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);

  useEffect(() => {
    const validationResponse = validatePasswordv2(password);

    setIsValidLength(validationResponse.isValidLength);
    setHasUpperCase(validationResponse.hasUpperCase);
    setHasNumber(validationResponse.hasNumber);
    setHasSymbol(validationResponse.hasSymbol);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);
  return (
    <Box sx={{ width: '100%' }}>
      <TextLabel className="text-heading-small" variant="body2">
        Your password must include at least:
      </TextLabel>
      <TextLabel
        className="text-heading-small"
        variant="body2"
        color={isValidLength ? 'success.main' : 'error.main'}
      >
        14 characters
      </TextLabel>

      <TextLabel
        className="text-heading-small"
        variant="body2"
        color={hasUpperCase ? 'success.main' : 'error.main'}
      >
        1 uppercase letter
      </TextLabel>

      <TextLabel
        className="text-heading-small"
        variant="body2"
        color={hasNumber ? 'success.main' : 'error.main'}
      >
        one number
      </TextLabel>

      <TextLabel
        className="text-heading-small"
        variant="body2"
        color={hasSymbol ? 'success.main' : 'error.main'}
      >
        one special character
      </TextLabel>
    </Box>
  );
};

export default PasswordValidation;